import * as types from './actionTypes';

export { updateToast };

function updateToast(payload) {
  return {
    type: types.UPDATE_TOAST,
    payload,
  };
}
