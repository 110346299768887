import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from 'twin.macro';
import { withTranslation } from 'react-i18next';
import { updateToast } from '../services';
import { setUserProperties } from '@/services/analytics/SetUserProperties';

class Toast extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.messages = {
      msg_not_internet: t('youAreNotConnectedToTheInternet'),
    };
  }
  componentDidMount() {
    window.addEventListener('offline', () => {
      this.handleOnOffline();
    });
    window.addEventListener('online', () => {
      this.handleOnOnline();
    });
  }

  render() {
    return null;
    const { isShow, message } = this.props.toast;
    if (!isShow) {
      return null;
    }
    return (
      <DivStyled className="toast">
        <span className="toast--msg">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {message}
        </span>
      </DivStyled>
    );
  }

  handleOnOffline = () => {
    console.log('on offline');
    const { updateToast } = this.props;
    updateToast({
      isShow: true,
      message: this.messages.msg_not_internet,
    });
  };

  handleOnOnline = () => {
    console.log('on online');
    const { updateToast } = this.props;
    setUserProperties();
    updateToast({
      isShow: false,
      message: '',
    });
  };
}

const DivStyled = styled.div`
  font-size: 1rem;
  position: fixed;
  top: 10%;
  z-index: 99999999;
  text-align: center;
  width: 100%;
  .toast {
    &--msg {
      font-size: 1em;
      color: white;
      background-color: #ebb511;
      padding: 0.25em 0.5em;
      border-radius: 2px;
    }
  }
`;

const mapStateToProps = state => ({
  toast: state.toast,
});

const mapDispatchToProps = {
  updateToast: payload => updateToast(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Toast));
